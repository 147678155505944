import React, { ReactNode, createContext, useContext } from "react";
import useOktaIdentity from "../hooks/oktaHook";

interface AuthProviderProps {
  children: ReactNode;
}

// TODO: type
interface AuthenticationState {
  whoami: Object | null;
  accessToken: string | null;
  isAuthenticating: boolean;
  clearIdentity?: () => void;
  logout?: () => void;
  refreshToken?: string | ((newValue: string) => void);
  setRefreshToken?: string | ((newValue: string) => void);
  setAccessToken?: any;
  setImpersonation?: any;
  clearImpersonation?: any;
}

const defaultState: AuthenticationState = {
  whoami: null,
  accessToken: null,
  isAuthenticating: false,
};

const AuthContext = createContext(defaultState);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const {
    whoami,
    accessToken,
    refreshToken,
    isLoading,
    clearIdentity,
    logout,
    setAccessToken,
    setRefreshToken,
    setImpersonation,
    clearImpersonation,
  } = useOktaIdentity();

  return (
    <AuthContext.Provider
      value={{
        whoami,
        accessToken,
        isAuthenticating: isLoading,
        clearIdentity,
        logout,
        refreshToken,
        setRefreshToken,
        setAccessToken,
        setImpersonation,
        clearImpersonation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
