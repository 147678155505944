import { useState, useCallback } from "react";
import Cookies from "js-cookie";

export default function useCookie(
  name: string,
  secure: boolean = true,
  expiresInSeconds: number = 3600,
  initialValue: string | undefined = undefined,
) {
  const [value, setValue] = useState(() => {
    const cookie = Cookies.get(name);

    if (cookie) return cookie;
    else if (initialValue != null) {
      Cookies.set(name, initialValue, {
        secure: secure,
        expires: new Date(new Date().getTime() + expiresInSeconds * 1000),
      });

      return initialValue;
    }
  });

  const updateCookie = useCallback(
    (
      newValue: string,
      secure: boolean = true,
      expiresInSeconds: number = 3600,
    ) => {
      if (newValue != null) {
        Cookies.set(name, newValue, {
          secure,
          expires: new Date(new Date().getTime() + expiresInSeconds * 1000),
        });
      }

      setValue(newValue);
    },
  );

  const removeCookie = useCallback(() => {
    Cookies.remove(name);
    setValue(null);
  });

  return [value, updateCookie, removeCookie];
}
